<template>
  <div class="diagnoseCom">
    <div class="header">
      <div @click="toggleSlide">
        <img class="slideIcon" :class="{'isSlideUp' : diagnoseItem.isSlideUp}" src="../../assets/images/detail/slide_up.png" alt="">
        <img class="titleIcon" src="../../assets/images/detail/diagnose_title_icon.png" alt="">
        <span>诊断信息{{ Number(diagnoseIndex) + 1 }}</span>
      </div>
      <img
        v-if="diagnoseList.length > 1 && !diagnoseItem.id"
        class="deleteIcon"
        src="../../assets/images/detail/delete_icon.png"
        alt=""
        @click="removeDiagnoseItem"
      >
    </div>
    <section v-show="diagnoseItem.isSlideUp" class="container">
      <!--诊断过程-->
      <h3>诊断过程</h3>
      <div class="diagnoseProgress">
        <div class="item">
          <label>系统</label>
          <div class="content">
            <select v-model="diagnoseItem.system_id" :type="diagnoseItem.system_id ? '' : 'placeholder'" @change="changeSystemId">
              <option value="" selected disabled style="display: none;">请选择</option>
              <option
                v-for="system in systemList"
                :key="system.id"
                :value="system.id"
              >{{ system.name }}</option>
            </select>
          </div>
        </div>
        <div class="item">
          <label>部位</label>
          <div class="content">
            <select v-model="diagnoseItem.part_id" :type="diagnoseItem.part_id ? '' : 'placeholder'" :disabled="!diagnoseItem.system_id" @change="changePartId">
              <option value="" selected disabled style="display: none;">请选择</option>
              <option
                v-for="part in partList"
                :key="part.id"
                :value="part.id"
              >{{ part.name }}</option>
            </select>
            <input v-if="diagnoseItem.part_id === '0'" v-model="diagnoseItem.part_name" placeholder="请输入部位名称">
          </div>
        </div>
        <div class="item">
          <label>故障内容</label>
          <div class="content">
            <select v-model="diagnoseItem.fault_id" :type="diagnoseItem.fault_id ? '' : 'placeholder'" :disabled="!diagnoseItem.part_id" @change="changeFaultId">
              <option value="" selected disabled style="display: none;">请选择</option>
              <option
                v-for="fault in faultList"
                :key="fault.id"
                :value="fault.id"
              >{{ fault.name }}</option>
            </select>
            <input v-if="diagnoseItem.fault_id === '0'" v-model="diagnoseItem.fault_name" placeholder="请输入故障内容">
          </div>
        </div>
        <div class="item">
          <label>故障代码</label>
          <div class="content">
            <input v-model="diagnoseItem.fault_code" type="text" placeholder="填写故障代码">
          </div>
        </div>

      </div>

      <!--测量数据-->
      <div class="card metricalData">
        <div>
          <label>测量数据</label>
        </div>
        <div
          v-for="(item, index) in diagnoseItem.metrical_data"
          :key="index"
          class="metricalDataItem"
        >
          <input v-model="item.name" type="text" placeholder="参数名称">
          <input v-model="item.value" type="text" placeholder="数据">
          <input v-model="item.unit" type="text" placeholder="单位">
          <div class="removeIcon" @click="removeMetricalDataItem(index)">
            <img v-if="diagnoseItem.metrical_data.length > 1" src="../../assets/images/detail/minus.png" alt="">
          </div>
        </div>
        <div class="btnGroup">
          <!--          <div class="cancelBtn">删除</div>-->
          <div class="confirmBtn" @click="addMetricalDataItem">添加参数 +</div>
        </div>
      </div>
      <!--故障原因-->
      <div class="faultReason">
        <div class="item">
          <label>故障原因</label>
          <textarea v-model="diagnoseItem.fault_reason" placeholder="请输入故障原因" />
        </div>
      </div>

      <!--图片上传-->
      <div class="imageList">
        <div class="item">
          <label>图片上传</label>
        </div>
        <van-uploader
          v-model="diagnoseItem.photo_list"
          class="uploader"
          multiple
          :after-read="afterReadImage"
        />
      </div>

      <!--视频上传-->
      <div class="videoList">
        <div class="item">
          <label>视频上传(选填)</label>
        </div>
        <!--        <input id="uploadVideo" type="file"  @change="changeUploadVideo"/>-->
        <van-uploader
          v-model="diagnoseItem.video_list"
          class="uploader"
          accept="video/*"
          result-type="file"
          :after-read="afterReadVideo"
        >
          <template #preview-cover="item">
            <video
              class="video"
              :src="item.url"
              controls
              playsinline="false"
              webkit-playsinline="false"
              x5-playsinline="false"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              x-webkit-airplay="true"
              preload="auto"
              style="object-fit:fill"
            />
          </template>
        </van-uploader>
      </div>
      <!--诊断结论-->
      <h3>诊断结论</h3>
      <div
        v-for="(item, index) in diagnoseItem.result"
        :key="index"
        class="resultData"
      >
        <div class="card">
          <div class="item solution">
            <div>
              <label>排除方法</label>
            </div>
            <div class="content">
              <select v-model="item.solution" :type="item.solution ? '' : 'placeholder'">
                <option value="" selected disabled style="display: none;">请选择</option>
                <option
                  v-for="solution in solutionList"
                  :key="solution.id"
                  :value="solution.id"
                >{{ solution.name }}</option>
              </select>
              <textarea v-model="item.description" placeholder="请输入诊断结论" />

            </div>
          </div>
          <div class="part">
            <div>
              <label>使用零部件信息</label>
            </div>
            <select v-model="item.fault_part_dict_id" :type="item.fault_part_dict_id ? '' : 'placeholder'">
              <option value="" selected disabled style="display: none;">请选择故障部位</option>
              <option
                v-for="faultPart in faultPartList"
                :key="faultPart.id"
                :value="faultPart.id"
              >{{ faultPart.name }}</option>
            </select>
            <div class="item">
              <input v-model="item.use_part_name" type="text" placeholder="使用零部件">
              <input v-model="item.use_part_no" type="text" placeholder="使用零部件编号">

            </div>
          </div>
          <div class="btnGroup">
            <div v-if="diagnoseItem.result.length > 1" class="cancelBtn" @click="removeResultDataItem(index)">删除</div>
            <div v-if="diagnoseItem.result.length - 1 === index" class="confirmBtn" @click="addResultDataItem">添加结论 +</div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import EXIF from 'exif-js'
import port from '@/api/port'
export default {
  name: 'DiagnoseCom',
  props: {
    diagnoseList: {
      type: Array,
      default: () => {
        return []
      }
    },
    diagnoseIndex: {
      type: [String, Number],
      default: 0
    },
    diagnoseItem: {
      type: Object,
      default: () => {
        return {}
      }
    },
    systemList: {
      type: Array,
      default: () => {
        return []
      }
    },
    solutionList: {
      type: Array,
      default: () => {
        return []
      }
    },
    faultPartList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    partList() {
      let systemItem = null
      if (this.diagnoseItem.system_id) {
        systemItem = this.systemList.find(item => {
          return item.id === this.diagnoseItem.system_id
        })
      }
      const arr = [{ id: '0', name: '其他', children: [] }]
      console.log(systemItem)
      return systemItem ? systemItem.children.concat(arr) : arr
    },
    faultList() {
      let partItem = null
      if (this.diagnoseItem.part_id) {
        partItem = this.partList.find(item => {
          return item.id === this.diagnoseItem.part_id
        })
      }
      console.log(partItem)
      const arr = [{ id: '0', name: '其他' }]
      return partItem ? partItem.children.concat(arr) : arr
    }
  },
  watch: {
    // 'diagnoseItem.system_id'() {
    //   this.diagnoseItem.part_id = ''
    //   this.diagnoseItem.part_name = ''
    // },
    // 'diagnoseItem.c'() {
    //   this.diagnoseItem.fault_id = ''
    //   this.diagnoseItem.fault_name = ''
    // }
  },
  created() {
  },
  methods: {
    changeSystemId() {
      this.diagnoseItem.part_id = ''
      this.diagnoseItem.part_name = ''
      this.diagnoseItem.fault_id = ''
      this.diagnoseItem.fault_name = ''
    },
    changePartId() {
      this.diagnoseItem.part_name = ''
      this.diagnoseItem.fault_id = ''
      this.diagnoseItem.fault_name = ''
    },
    changeFaultId() {
      this.diagnoseItem.fault_name = ''
    },
    toggleSlide() {
      this.$emit('toggleSlide')
    },
    removeDiagnoseItem() {
      this.$emit('removeDiagnoseItem')
    },
    addMetricalDataItem() {
      this.$emit('addMetricalDataItem')
    },
    removeMetricalDataItem(index) {
      this.$emit('removeMetricalDataItem', index)
    },
    addResultDataItem() {
      this.$emit('addResultDataItem')
    },
    removeResultDataItem(index) {
      this.$emit('removeResultDataItem', index)
    },

    afterReadImage(files) {
      console.log(files)
      if (Array.isArray(files)) {
        files.forEach(file => {
          this.imgPreview(file)
        })
      } else {
        this.imgPreview(files)
        // this.fileUpload(files, 'image')
      }
    },
    changeUploadVideo() {
      const dom = document.getElementById('uploadVideo')
      const file = dom.files[0]
      console.log(file.size)
      this.fileUpload({ file }, 'video')
    },
    afterReadVideo(files) {
      // if (Array.isArray(files)) {
      //   files.forEach(file => {
      //     if (file.size > 150 * 1024 * 1024) {
      //       this.$toast({
      //         message: '视频文件太大，建议不超过150MB'
      //       })
      //       return false
      //     }
      //     this.fileUpload(file, 'video')
      //   })
      // } else {
      if (files.file.size > 150 * 1024 * 1024) {
        this.$toast({
          message: '视频文件太大，建议不超过150MB'
        })
        return false
      }
      this.fileUpload(files, 'video')
      // }
    },
    fileUpload(file, type) {
      console.log(file)
      file.status = 'uploading'
      file.message = '上传中...'
      port.upload({
        type,
        file: file.file
      }).then(res => {
        file.status = 'done'
        file.message = '上传成功'
        file.url = res.data.file_url
        file.id = res.data.file_id
        console.log(file)
      }).catch(() => {
        file.status = 'failed'
        file.message = '上传失败'
      })
    },
    // 处理图片
    imgPreview(files) {
      const file = files.file
      let Orientation
      // 看支持不支持FileReader
      if (!file || !window.FileReader) {
        console.log('不支持FileReader')
        return
      }
      if (/^image/.test(file.type)) {
        // 去获取拍照时的信息，解决拍出来的照片旋转问题   npm install exif-js --save   这里需要安装一下包
        EXIF.getData(file, function() {
          Orientation = EXIF.getTag(this, 'Orientation')
        })
        // 创建一个reader
        const reader = new FileReader()
        const _this = this
        // 将图片2将转成 base64 格式
        reader.readAsDataURL(file)
        // 读取成功后的回调
        reader.onload = function(e) {
          const image = new Image()
          image.src = e.target.result
          file.src = this.result
          // console.log(this.result)
          image.onload = function() {
            let expectWidth = this.naturalWidth
            let expectHeight = this.naturalHeight
            if (
              this.naturalWidth > this.naturalHeight &&
                this.naturalWidth > 800
            ) {
              expectWidth = 800
              expectHeight =
                  (expectWidth * this.naturalHeight) / this.naturalWidth
            } else if (
              this.naturalHeight > this.naturalWidth &&
                this.naturalHeight > 1200
            ) {
              expectHeight = 1200
              expectWidth =
                  (expectHeight * this.naturalWidth) / this.naturalHeight
            }
            var canvas = document.createElement('canvas')
            var ctx = canvas.getContext('2d')
            canvas.width = expectWidth
            canvas.height = expectHeight
            ctx.drawImage(this, 0, 0, expectWidth, expectHeight)
            var base64 = null
            if (navigator.userAgent.match(/iphone/i)) {
              // 如果方向角不为1，都需要进行旋转 added by lzk
              if (Orientation != '' && Orientation != 1) {
                // console.log('旋转处理');
                switch (Orientation) {
                  case 6: // 需要顺时针（向左）90度旋转
                    _this.rotateImg(this, 'left', canvas)
                    break
                  case 8: // 需要逆时针（向右）90度旋转
                    _this.rotateImg(this, 'right', canvas)
                    break
                  case 3: // 需要180度旋转
                    _this.rotateImg(this, 'right', canvas) // 转两次
                    _this.rotateImg(this, 'right', canvas)
                    break
                }
              }
              base64 = canvas.toDataURL('image/jpeg', 0.8)
              _this.postImg(base64, files)
            } else if (navigator.userAgent.match(/Android/i)) {
              // 修复android
              base64 = canvas.toDataURL('image/jpeg', 0.8)
              _this.postImg(base64, files)
            } else {
              if (Orientation != '' && Orientation != 1) {
                switch (Orientation) {
                  case 6: // 需要顺时针（向左）90度旋转
                    _this.rotateImg(this, 'left', canvas)
                    break
                  case 8: // 需要逆时针（向右）90度旋转
                    _this.rotateImg(this, 'right', canvas)
                    break
                  case 3: // 需要180度旋转
                    _this.rotateImg(this, 'right', canvas) // 转两次
                    _this.rotateImg(this, 'right', canvas)
                    break
                }
              }
              base64 = canvas.toDataURL('image/jpeg', 0.8)
              _this.postImg(base64, files)
            }
            // console.log(_this.imgList)
          }
        }
      }
    },
    // 压缩图片
    compress(img, Orientation) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      // 瓦片canvas
      const tCanvas = document.createElement('canvas')
      const tctx = tCanvas.getContext('2d')
      // let initSize = img.src.length;
      let width = img.width
      let height = img.height
      // 如果图片大于四百万像素，计算压缩比并将大小压至400万以下
      let ratio
      if ((ratio = (width * height) / 4000000) > 1) {
        // console.log("大于400万像素");
        ratio = Math.sqrt(ratio)
        width /= ratio
        height /= ratio
      } else {
        ratio = 1
      }
      canvas.width = width
      canvas.height = height
      //    铺底色
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      // 如果图片像素大于100万则使用瓦片绘制
      let count
      if ((count = (width * height) / 1000000) > 1) {
        // console.log("超过100W像素");
        count = ~~(Math.sqrt(count) + 1) // 计算要分成多少块瓦片
        //      计算每块瓦片的宽和高
        const nw = ~~(width / count)
        const nh = ~~(height / count)
        tCanvas.width = nw
        tCanvas.height = nh
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < count; j++) {
            tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh)
            ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh)
          }
        }
      } else {
        ctx.drawImage(img, 0, 0, width, height)
      }
      // 修复ios上传图片的时候 被旋转的问题
      if (Orientation != '' && Orientation != 1) {
        switch (Orientation) {
          case 6: // 需要顺时针（向左）90度旋转
            this.rotateImg(img, 'left', canvas)
            break
          case 8: // 需要逆时针（向右）90度旋转
            this.rotateImg(img, 'right', canvas)
            break
          case 3: // 需要180度旋转
            this.rotateImg(img, 'right', canvas) // 转两次
            this.rotateImg(img, 'right', canvas)
            break
        }
      }
      // 进行最小压缩
      const ndata = canvas.toDataURL('image/jpeg', 0.1)
      tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0
      // console.log(ndata)
      return ndata
    },
    // 旋转图片
    rotateImg(img, direction, canvas) {
      // 最小与最大旋转方向，图片旋转4次后回到原方向
      const min_step = 0
      const max_step = 3
      if (img == null) return
      // img的高度和宽度不能在img元素隐藏后获取，否则会出错
      const height = img.height
      const width = img.width
      let step = 2
      if (step == null) {
        step = min_step
      }
      if (direction == 'right') {
        step++
        // 旋转到原位置，即超过最大值
        step > max_step && (step = min_step)
      } else {
        step--
        step < min_step && (step = max_step)
      }
      // 旋转角度以弧度值为参数
      const degree = (step * 90 * Math.PI) / 180
      const ctx = canvas.getContext('2d')
      switch (step) {
        case 0:
          canvas.width = width
          canvas.height = height
          ctx.drawImage(img, 0, 0)
          break
        case 1:
          canvas.width = height
          canvas.height = width
          ctx.rotate(degree)
          ctx.drawImage(img, 0, -height)
          break
        case 2:
          canvas.width = width
          canvas.height = height
          ctx.rotate(degree)
          ctx.drawImage(img, -width, -height)
          break
        case 3:
          canvas.width = height
          canvas.height = width
          ctx.rotate(degree)
          ctx.drawImage(img, -width, 0)
          break
      }
    },
    // 将base64转换为文件
    dataURLtoFile(dataurl, file) {
      var arr = dataurl.split(',')
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], file.name, {
        type: file.type
      })
    },
    // 提交图片到后端
    postImg(base64, files) {
      const file = this.dataURLtoFile(base64, files.file)
      // let formData = new window.FormData()
      // formData.append('file', file)
      // console.log(file, formData)
      // 提交图片
      files.status = 'uploading'
      files.message = '上传中...'
      port.upload({
        type: 'image',
        file
      }).then(res => {
        files.status = 'done'
        files.message = '上传成功'
        files.url = res.data.file_url
        files.id = res.data.file_id
        console.log(file)
      }).catch(() => {
        files.status = 'failed'
        files.message = '上传失败'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.diagnoseCom{
  background: #FFFFFF;
  margin-bottom: .02rem;
  .header{
    height: .85rem;
    background: #FFFFFF;
    box-shadow: 0 .07rem .1rem 0 rgba(0,0,0,0.05);
    padding: 0 .27rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    >div{
      display: flex;
      align-items: center;
    }
    .slideIcon{
      width: .41rem;
      height: .41rem;
      margin-right: .07rem;
      transition: .3s;
    }
    .isSlideUp{
      transform: rotate(90deg);
    }
    .titleIcon{
      width: .38rem;
      height: .38rem;
      margin-right: .07rem;
    }
    .deleteIcon{
      width: .41rem;
      height: .41rem;
    }
  }

  .container{
    padding: .32rem .27rem;
    margin-bottom: .14rem;
    h3{
      line-height: .38rem;
      font-size: .27rem;
      font-weight: 400;
      margin-bottom: .22rem;
      padding-left: .17rem;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: .05rem;
        width: .07rem;
        height: .27rem;
        background: #2F8AF1;
      }
    }
    .item{
      display: flex;
      align-items: center;
      margin-bottom: 0.14rem;
    }
    label{
      font-size: .23rem;
      font-weight: 400;
      color: #333333;
      line-height: .58rem;
      margin-right: .2rem;
      display: inline-block;
    }
    select {
      height: 0.58rem;
      border-radius: 0.03rem;
      border: 0.02rem solid rgba(212, 212, 212, 1);
      padding: 0.12rem 0.2rem;
      background: url("../../assets/images/r-icon.png") no-repeat right center;
      background-size: 0.34rem 0.34rem;
      color: #333;
      option{
        color: #333;
      }
    }
    select[type="placeholder"]{
      color: #999;
    }

    .card{
      padding: .2rem .34rem .27rem;
      box-shadow: 0 0 .12rem .03rem rgba(47,138,241,0.2);
      border-radius: .1rem;
      margin-bottom: .41rem;
      .btnGroup{
        display: flex;
        justify-content: center;
        padding-top: .06rem;
        div{
          width: 1.71rem;
          height: .58rem;
          border-radius: .29rem;
          border: .02rem solid #2F8AF1;
          font-size: .24rem;
          line-height: .58rem;
          text-align: center;
        }
        div ~div{
          margin-left: .68rem;
        }
        .confirmBtn{
          background: #2F8AF1;
          color: #fff;
        }
        .cancelBtn{
          color: #2F8AF1;
        }
      }
    }
    input {
      height: 0.58rem;
      border-radius: 0.03rem;
      border: 0.02rem solid rgba(212, 212, 212, 1);
      padding-left: 0.2rem;
      box-sizing: border-box;
    }

    textarea {
      width: 4.69rem;
      height: 1.88rem;
      border-radius: 0.1rem;
      border: 0.02rem solid rgba(212, 212, 212, 1);
      padding: 0.2rem;
      box-sizing: border-box;
    }
  }
  /*诊断过程*/
  .diagnoseProgress{
    padding: 0 .46rem;
    margin-bottom: .26rem;
    .item{
      align-items: flex-start;
    }
    label{
      width: .96rem;
    }
    .content{
      flex: 1;
    }
    select{
      margin-bottom: .14rem;
    }
    select, input{
      width: 100%;
    }

  }

  /*测量数据*/
  .metricalData{
    label{
      margin-bottom: .14rem;
      display: block;
    }
    .metricalDataItem{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.14rem;

      input{
        width: 28%;
      }
      .removeIcon{
        width: .48rem;
        height: .48rem;
        img{
          width: 100%;
        }
      }
    }

  }
  /*故障原因*/
  .faultReason{
    margin-bottom: .31rem;
    .item{
      align-items: flex-start;
    }
  }

  /*诊断结论*/
  .resultData{
    .solution{
      margin-bottom: .43rem;
      align-items: flex-start;
      .content{
        width: 4rem;
        select,
        textarea{
          width: 100%;
        }
        select{
          margin-bottom: .14rem;
        }
      }
    }
    .part{
      .item{
        justify-content: space-between;
      }
      select{
        width: 100%;
        margin-bottom: .14rem;
      }
      input{
        width: 2.5rem;
      }
    }
  }
}
</style>
<style lang="less">
  .diagnoseCom{
    .van-uploader{
      .van-uploader__upload{
        width: 1.77rem;
        height: 1.77rem;
        border-radius: .1rem;
        background: #F3F3F3;
        .van-uploader__upload-icon{
          font-size: .56rem;
          color: #C4C4C4;
        }
      }
      .van-uploader__preview{
        margin: 0 .27rem .27rem 0;
      }
      .van-uploader__preview-image,
      .van-uploader__file{
        width: 1.77rem;
        height: 1.77rem;
        border-radius: .1rem;
        background: #F3F3F3;
        video, img{
          width: 100%;
          height: 100%;
        }
        video{
          background: #000;
          border-radius: .1rem;
        }
      }
    }

    .imageList, .videoList{
      margin: 0 -.27rem .04rem 0;
    }
  }

</style>
